import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { localDate, LocalDateInput, LocalDateUnit } from '@naturalcycles/js-lib'
import { cpfRegex, phoneNumberRegex } from '@naturalcycles/shared'

const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/i

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return emailRegex.test(control.value) ? null : { invalidEmail: true }
  }
}

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return phoneNumberRegex.test(control.value) ? null : { invalidPhone: true }
  }
}

export function cpfValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value?.length === 14 && cpfRegex.test(control.value)
      ? null
      : { invalidCPF: true }
  }
}

type DateOperatorInput = [number, LocalDateUnit]

/**
 * A validator that checks if the selected date is within a specific range
 * The range is inclusive
 */
export function inDateRangeValidator(
  minDate: LocalDateInput,
  maxDate: LocalDateInput,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value

    if (!localDate.isValid(value)) {
      return null
    }
    const selectedDate = localDate(value)

    if (!selectedDate.isBetween(minDate, maxDate, '[]')) {
      return { invalidDate: true }
    }
    return null
  }
}

/**
 * A validator that checks if the selected date is within a relative range from the current date, e.g. 1 year ago to 1 day from now
 * The range is inclusive
 */
export function inRelativeDateRangeValidator(
  minTimeAgo: DateOperatorInput,
  maxTimeInFuture: DateOperatorInput,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const [minValue, minUnit] = minTimeAgo
    const [maxValue, maxUnit] = maxTimeInFuture

    const currentDate = localDate.today()

    const minDate = currentDate.minus(minValue, minUnit)
    const maxDate = currentDate.plus(maxValue, maxUnit)

    return inDateRangeValidator(minDate, maxDate)(control)
  }
}

/**
 * A validator that compares whether two form controls within a form group have the same value
 *
 * @param source form control that has the original value
 * @param target form control that is expeccted to match the original value
 */
export function matchValidator(source: string, target: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const sourceCtrl = control.get(source)
    const targetCtrl = control.get(target)

    if (sourceCtrl?.value !== targetCtrl?.value && sourceCtrl?.dirty && targetCtrl?.dirty) {
      return { mismatch: true }
    }

    return null
  }
}

/**
 * A validator that checks if the case of the input is uppercase
 */
export function uppercaseValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value === control.value.toUpperCase() ? null : { invalidCase: true }
  }
}

/**
 * A validator that checks if the length of the input is within a specific range
 */
export function lengthValidator(min: number, max: number = min): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value.length >= min && control.value.length <= max
      ? null
      : { invalidLength: true }
  }
}

/**
 * A validator that checks if the input starts with a specific value
 */
export function startsWithValidator(value: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value.startsWith(value) ? null : { invalidStart: true }
  }
}
